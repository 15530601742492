import React from 'react';

export default class Header extends React.Component {
    render() {
        return (<header className="header-area">
            <div className="container">
                <div className="row">
                    <div className="col-12 position-relative">
                        <nav className="main-nav">
                            <a href="/" className="logo">
                                <img src="/assets/tytoo_w.png" width="120" className="light-logo mtlogo" alt="Tytoo" />
                                <img src="/assets/tytoo_bg.png" width="120" className="dark-logo mtlogo" alt="Tytoo" />
                            </a>
                            <ul className="nav">
                                <li><a href="/">HOME</a></li>
                                <li><a href="/domain">DOMAIN</a></li>
                                <li><a href="/authentication">AUTHENTICATION</a></li>
                                <li><a href="/certification">CERTIFICATION</a></li>
                                <li><a href="/blocks">BLOCKS</a></li>
                                <li><a href="/slices">SLICES</a></li>
                                <li><a href="/tx">TRANSACTIONS</a></li>
                            </ul>
                            <a href="#menu" className='menu-trigger'>
                                <span>Menu</span>
                            </a>
                        </nav>
                    </div>
                </div>
            </div>
        </header>)
    }
}